import style from "./style.module.scss";

import React, { useEffect } from "react";
import classnames from "classnames";

import { Icon, jnjError } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import useJnjBranding from "@jmc/utils/hooks/useJnjBranding";

export type TextFieldType = "text" | "number";
export type TextFieldVariant = "default" | "dark" | "light";

export type RoundedCorners = "default" | "round";

interface Props {
    id?: string;
    type?: TextFieldType;
    name: string;
    placeholder?: string;
    value?: string | number;
    onChange?: ({ target: { value } }: { target: { value: string } }) => void;
    startAdornment?: JSX.Element;
    endAdornment?: JSX.Element;
    multiline?: boolean;
    error?: boolean;
    onClick?: () => void;
    hasFocus?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    border?: boolean;
    minHeight?: string;
    variant?: TextFieldVariant;
    corners?: RoundedCorners;
    onKeyDown?: (event: KeyboardEvent) => void;
    list?: string;
}

export const TextField = React.forwardRef(
    (
        {
            type = "text",
            onChange,
            error = false,
            startAdornment,
            endAdornment,
            multiline = false,
            onClick,
            disabled = false,
            readOnly = false,
            border = true,
            minHeight = undefined,
            variant = "default",
            onKeyDown = undefined,
            corners = "default",
            id = "textarea",
            list,
            hasFocus = false,
            ...others
        }: Props,
        ref: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { jnjFullBranded } = useJnjBranding();
        useEffect(() => {
            if (hasFocus && ref?.current) {
                ref?.current?.focus();
            }
        }, []);

        return (
            <div
                className={classnames(
                    style.input,
                    style[variant],
                    !border && style.input_removeBorder,
                    onClick && style.clickable,
                    error && style.error,
                    readOnly && jnjFullBranded && style.readOnly,
                    style[`corners-${corners}`],
                )}
                data-test-id="TextField.Input"
                onClick={onClick}
                style={{ minHeight: minHeight ? minHeight : "initial" }}
                role="presentation"
            >
                {startAdornment && <div className={classnames(style.adornment, style.start)}>{startAdornment}</div>}
                {multiline ? (
                    <textarea
                        id={id}
                        onChange={onChange}
                        onKeyDown={(e) => onKeyDown && onKeyDown(e as unknown as KeyboardEvent)}
                        disabled={disabled}
                        ref={ref as React.RefObject<HTMLTextAreaElement>}
                        data-test-id={`TextField.TextArea.${others.name}`}
                        {...others}
                    />
                ) : (
                    <input
                        id={id}
                        type={type}
                        onChange={onChange}
                        onKeyDown={(e) => onKeyDown && onKeyDown(e as unknown as KeyboardEvent)}
                        ref={ref as React.RefObject<HTMLInputElement>}
                        disabled={disabled}
                        readOnly={readOnly}
                        data-test-id={`TextField.Input.${others.name}`}
                        list={list}
                        {...others}
                    />
                )}
                {error && jnjFullBranded && (
                    <Icon color="error" icon={jnjError} type="jnj" verticalAlignMiddle={true} />
                )}
                {endAdornment && <div className={classnames(style.adornment, style.end)}>{endAdornment}</div>}
            </div>
        );
    },
);

TextField.displayName = "TextField";
